import { Spinner } from "@blueprintjs/core";
import { BatteryMetrics1h } from "@ec1/types/BatteryMetrics1h";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import HighchartsReact from "highcharts-react-official";
import Highcharts, {
  TooltipFormatterContextObject,
} from "highcharts/highstock";
import { DateTime, Duration } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFetch } from "src/common/fetcher/effects";
import { ChartStateV2, getChartGranularityV2 } from "src/ui/utils/chartUtils";

interface PlatformBatteryMetricsBatteryPowerChartProps {
  batteryId: number;
  height?: number;
  chartState: ChartStateV2;
  setChartState: React.Dispatch<React.SetStateAction<ChartStateV2>>;
  timezone?: string;
  timezoneLabel?: string;
  lastMetricTimestamp?: string;
}

export default function PlatformBatteryMetricsBatteryPowerChart({
  batteryId,
  height = 320,
  chartState,
  setChartState,
  timezone,
  timezoneLabel,
  lastMetricTimestamp,
}: PlatformBatteryMetricsBatteryPowerChartProps) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [chartType, setChartType] = useState("SOC");

  const handleChartTypeChange = (event: SelectChangeEvent) => {
    setChartType(event.target.value as string);
  };

  const fetchUrlMetrics = useMemo(() => {
    if (
      chartState.start &&
      chartState.end &&
      chartState.start <= DateTime.now().setZone(timezone || "system")
    ) {
      return `/api/devices/${
        chartState.granularity
      }/battery-metrics/?battery=${batteryId}&utc_time__gte=${chartState.start
        .toUTC()
        .toISO({ includeOffset: false })}&utc_time__lte=${chartState.end
        .toUTC()
        .toISO({ includeOffset: false })}&ordering=utc_time`;
    }
    return null;
  }, [batteryId, chartState, timezone]);

  const fetchUrlForecasts = null;

  /* Uncomment the below to enable forecasts */
  // const fetchUrlForecasts = useMemo(() => {
  //   if (
  //     chartState.start &&
  //     chartState.end &&
  //     (!lastMetricTimestamp ||
  //       (lastMetricTimestamp &&
  //         chartState.end > DateTime.fromISO(lastMetricTimestamp)))
  //   ) {
  //     return `/api/devices/${
  //       chartState.granularity
  //     }/battery-forecasts/?battery=${batteryId}&utc_time__gte=${chartState.start
  //       .toUTC()
  //       .toISO({ includeOffset: false })}&utc_time__lte=${chartState.end
  //       .toUTC()
  //       .toISO({ includeOffset: false })}&ordering=utc_time`;
  //   }
  //   return null;
  // }, [batteryId, chartState, lastMetricTimestamp]);

  const { data: metricData, isLoading: isLoadingMetrics } = useFetch<{
    results: BatteryMetrics1h[];
  }>(fetchUrlMetrics);

  const { data: forecastData, isLoading: isLoadingForecasts } = useFetch<{
    results: BatteryMetrics1h[];
  }>(fetchUrlForecasts);

  const dataLoaded = useMemo(() => {
    return (
      (!fetchUrlMetrics || (fetchUrlMetrics && metricData)) &&
      (!fetchUrlForecasts || (fetchUrlForecasts && forecastData))
    );
  }, [fetchUrlForecasts, fetchUrlMetrics, forecastData, metricData]);

  const noDataAvailable = useMemo(() => {
    return fetchUrlMetrics === null && fetchUrlForecasts === null;
  }, [fetchUrlForecasts, fetchUrlMetrics]);

  useEffect(() => {
    if (noDataAvailable) {
      setIsLoading(false);
    } else if (dataLoaded && chartRef.current?.chart) {
      const chart = chartRef.current.chart;

      const metrics = metricData
        ? metricData.results.map((item: BatteryMetrics1h) => {
            return [
              DateTime.fromISO(item.utc_time, {
                zone: "utc",
              }).toMillis(),
              chartType === "Power" ? item.battery_power_in_kw : item.soc,
            ];
          })
        : [];

      const lastMetricTimestamp =
        metrics.length > 0 ? metrics[metrics.length - 1][0] ?? 0 : 0;

      const forecasts = forecastData
        ? forecastData.results
            .map((item: BatteryMetrics1h) => {
              return [
                DateTime.fromISO(item.utc_time, {
                  zone: "utc",
                }).toMillis(),
                chartType === "Power" ? item.battery_power_in_kw : item.soc,
              ];
            })
            .filter((item) => item[0] && item[0] > lastMetricTimestamp)
        : ([] as any);

      const data = [...metrics, ...forecasts]
        .sort((a, b) => a[0] - b[0])
        .reduce((acc: [number, number][], curr) => {
          const existingIndex = acc.findIndex((item) => item[0] === curr[0]);
          if (existingIndex !== -1) {
            // If timestamp already exists, keep the value from metrics
            if (metrics.some((item) => item[0] === curr[0])) {
              return acc;
            }
            // Otherwise, update with the new value only if it's after the last metric
            if (curr[0] > lastMetricTimestamp) {
              acc[existingIndex] = curr;
            }
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);

      // Create padded data set
      const paddedData: [number, number | null][] = [];
      if (chartState.start && chartState.end) {
        let currentTime = chartState.start;
        const endTime = chartState.end;

        // Adjust the start time based on granularity
        switch (chartState.granularity) {
          case "15m":
            currentTime = currentTime
              .startOf("hour")
              .plus({ minutes: Math.floor(currentTime.minute / 15) * 15 });
            break;
          case "30m":
            currentTime = currentTime
              .startOf("hour")
              .plus({ minutes: Math.floor(currentTime.minute / 30) * 30 });
            break;
          case "1h":
            currentTime = currentTime.startOf("hour");
            break;
          case "1d":
            currentTime = currentTime.startOf("day");
            break;
        }

        const granularityDuration = Duration.fromObject(
          chartState.granularity === "15m"
            ? { minutes: 15 }
            : chartState.granularity === "30m"
            ? { minutes: 30 }
            : chartState.granularity === "1h"
            ? { hours: 1 }
            : { days: 1 }
        );

        while (currentTime <= endTime) {
          paddedData.push([currentTime.toMillis(), null]);
          currentTime = currentTime.plus(granularityDuration);
        }
      }

      // Populate padded data with actual values
      data.forEach(([timestamp, value]) => {
        const index = paddedData.findIndex(([x]) => x === timestamp);
        if (index !== -1) {
          paddedData[index][1] = value;
        }
      });

      // Update the data
      chart.series[0].setData(paddedData, false);

      /* Uncomment when legends are enabled */
      // Ensure both legend items are enabled
      // chart.series.forEach((series, index) => {
      //   if (index > 0) {
      //     // Skip the main data series
      //     series.setVisible(true, false);
      //   }
      // });

      // Set the extremes based on chartState
      if (chartState.start && chartState.end) {
        chart.xAxis[0].setExtremes(
          chartState.start.toMillis(),
          chartState.end.toMillis()
        );
      }

      // Redraw the chart
      chart.redraw();

      setIsLoading(false);
    }
  }, [
    chartState,
    chartType,
    dataLoaded,
    forecastData,
    isLoading,
    isLoadingForecasts,
    isLoadingMetrics,
    metricData,
    noDataAvailable,
    timezone,
  ]);

  const chartOptions = useMemo(() => {
    const localNow = DateTime.now().setZone(timezone || "system");
    const localDate = localNow.toMillis();

    return {
      chart: {
        height: height - 65,
        marginRight: 20,
        marginLeft: 64,
        marginTop: 32,
      },
      time: {
        timezone,
        useUTC: timezone !== undefined,
      },
      rangeSelector: {
        enabled: false,
      },
      xAxis: {
        minRange: 60 * 1000,
        crosshair: true,
        title: {
          y: 5,
          text: `Time<br><span style="font-size: 11px; color: #999;">${timezoneLabel}</span>`,
        },
        plotLines: [
          {
            color: "#544fc5",
            width: 2,
            value: localNow.toMillis(),
          },
        ],
        events: {
          afterSetExtremes: function (e: any) {
            const chart = e.target.chart;

            if (!chart || !e.min || !e.max || e.trigger !== "zoom") {
              return;
            }

            const minDateTime = DateTime.fromMillis(Math.round(e.min))
              .setZone(timezone)
              .startOf("minute");
            const maxDateTime = DateTime.fromMillis(Math.round(e.max))
              .setZone(timezone)
              .startOf("minute");

            const granularity = getChartGranularityV2(minDateTime, maxDateTime);

            setChartState({
              start: minDateTime,
              end: maxDateTime,
              granularity: granularity,
            });
          },
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          if (typeof this.x !== "number" || typeof this.y !== "number") {
            return "";
          }

          const pointDate = DateTime.fromMillis(this.x).setZone(
            timezone || "system"
          );

          let format = "cccc, dd LLLL HH:mm";
          let startHour = pointDate.toFormat("HH:mm");
          let endHour = pointDate.plus({ hours: 1 }).toFormat("HH:mm");

          const chart = this.series.chart;
          const xAxis = chart.xAxis[0];
          const min = xAxis.min || 0;
          const max = xAxis.max || 0;

          const granularity = getChartGranularityV2(
            DateTime.fromMillis(Math.round(min)),
            DateTime.fromMillis(Math.round(max))
          );

          switch (granularity) {
            case "15m":
              endHour = pointDate.plus({ minutes: 15 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "30m":
              endHour = pointDate.plus({ minutes: 30 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "1h":
              endHour = pointDate.plus({ hours: 1 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "1d":
              format = "cccc, dd LLLL";
              break;
            default:
              break;
          }

          const dateStr = pointDate.toFormat(format);
          const seriesName = this.series.name;
          const valueStr = `${this.y.toFixed(chartType === "Power" ? 2 : 1)} ${
            chartType === "Power" ? "kW" : "%"
          }`;

          const bulletPoint = `<span style="color:${this.color};">\u25CF</span>`;

          const forecast = this.x > localDate ? " (Forecast)" : "";

          return `<div style="font-size: 12px; margin-bottom: 5px;">${dateStr}</div>${bulletPoint} ${seriesName}${forecast}: <b>${valueStr}</b>`;
        },
        style: {
          fontFamily: "Barlow, sans-serif",
        },
      },
      yAxis: [
        {
          endOnTick: chartType === "Power",
          min: chartType === "Power" ? undefined : 0,
          max: chartType === "Power" ? undefined : 101, // +1 for linewidth
          title: {
            text: chartType === "Power" ? "Power (kW)" : "Percentage (%)",
          },
        },
      ],
      series: [
        {
          type: chartType === "Power" ? "areaspline" : "column",
          lineWidth: chartType === "Power" ? 1 : 2,
          color:
            chartType === "Power"
              ? "rgba(156, 39, 176, 0.75)"
              : "rgb(156, 39, 176)",
          name: chartType === "Power" ? "Power" : "Battery Level",
          dataGrouping: {
            enabled: false,
          },
          showInLegend: false,
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color:
                chartType === "Power"
                  ? "rgba(156, 39, 176, 0.75)"
                  : "rgb(156, 39, 176)",
            },
            {
              color: "rgb(156, 39, 176,0.5)",
              fillColor: "rgba(156, 39, 176,0.3)",
              dashStyle: "ShortDash",
            },
          ],
        },
        /* Uncomment when forecasting is enabled */
        // Fake series to add legend toggles
        // {
        //   name: "Actual",
        //   type: "line",
        //   color: "rgb(156, 39, 176)",
        //   events: {
        //     legendItemClick: function (e: any) {
        //       const series = e.target.chart.series[0];
        //       if (series) {
        //         series.points.forEach((point: any) => {
        //           if (point.x <= localDate) {
        //             if (e.target.visible) {
        //               point.oldY = point.y;
        //               point.update({ y: null }, false, false);
        //             } else if (point.oldY !== undefined) {
        //               point.update({ y: point.oldY }, false, false);
        //               delete point.oldY;
        //             }
        //           }
        //         });
        //         series.chart.redraw();
        //       }
        //     },
        //   },
        // },
        // {
        //   name: "Forecast",
        //   type: "line",
        //   color: "rgba(156, 39, 176, .75)",
        //   dashStyle: "ShortDash",
        //   events: {
        //     legendItemClick: function (e: any) {
        //       const series = e.target.chart.series[0];
        //       if (series) {
        //         series.points.forEach((point: any) => {
        //           if (point.x > localDate) {
        //             if (e.target.visible) {
        //               point.oldY = point.y;
        //               point.update({ y: null }, false, false);
        //             } else if (point.oldY !== undefined) {
        //               point.update({ y: point.oldY }, false, false);
        //               delete point.oldY;
        //             }
        //           }
        //         });
        //         series.chart.redraw();
        //       }
        //     },
        //   },
        // },
      ],
      /* Uncomment when forecasting is enabled */
      // legend: {
      //   enabled: true,
      //   align: "right",
      //   verticalAlign: "top",
      //   x: -10,
      //   y: -10,
      //   padding: 0,
      //   symbolWidth: 14,
      //   itemStyle: {
      //     color: "white",
      //     fontFamily: "Barlow",
      //   },
      //   itemHoverStyle: {
      //     color: "#ccc",
      //     fontFamily: "Barlow",
      //   },
      // },
      loading: {
        labelStyle: {
          color: "transparent",
        },
        style: { backgroundColor: "transparent" },
      },
      plotOptions: {
        column: {
          maxPointWidth: 20,
        },
        series: {
          pointPlacement: "on",
        },
      },
    };
  }, [timezone, height, timezoneLabel, chartType, setChartState]);

  return (
    <div
      style={{
        height: height,
        backgroundColor: "rgb(37, 42, 49)",
        borderRadius: 4,
        padding: 20,
        color: "white",
      }}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontSize: 21,
              marginBottom: 10,
            }}
          >
            <b>Battery</b>
          </div>
          <div
            style={{
              width: "100%",
              flex: 1,
              textAlign: "right",
              fontSize: "16px",
              marginBottom: 10,
              color: "rgba(255,255,255,.75)",
              marginTop: 4,
            }}
          >
            <FormControl
              sx={{ width: 140 }}
              disabled={isLoading || isLoadingMetrics || isLoadingForecasts}
            >
              <Select
                id="chart-type-select"
                value={chartType}
                onChange={handleChartTypeChange}
                sx={{
                  height: 26,
                  fontSize: "0.875rem",
                  "& .MuiSelect-select": {
                    paddingTop: "3px",
                    paddingBottom: "3px",
                    textAlign: "left",
                  },
                  "& .MuiOutlinedInput-input": {
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      "& .MuiMenu-list": {
                        padding: "2px 0",
                      },
                    },
                  },
                }}
              >
                <MenuItem
                  value="Power"
                  sx={{
                    height: 26,
                    fontSize: "0.875rem",
                    padding: "4px 10px",
                  }}
                >
                  Power
                </MenuItem>
                <MenuItem
                  value="SOC"
                  sx={{
                    height: 26,
                    fontSize: "0.875rem",
                    padding: "4px 10px",
                  }}
                >
                  Battery Level
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div>
        {isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: height - 100,
            }}
          >
            <Spinner />
          </div>
        )}
        {noDataAvailable ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 260,
              width: "100%",
            }}
          >
            No data available
          </div>
        ) : (
          <div
            style={{
              visibility: isLoading ? "hidden" : "visible",
            }}
          >
            <HighchartsReact
              key={chartType}
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={chartOptions}
              ref={chartRef}
            />
            {(isLoadingMetrics || isLoadingForecasts) && (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  height: height - 70,
                  marginTop: 45 - height,
                }}
              >
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
