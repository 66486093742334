import { Spinner } from "@blueprintjs/core";
import { BatteryMetrics1h } from "@ec1/types/BatteryMetrics1h";
import { Price } from "@ec1/types/Price";
import HighchartsReact from "highcharts-react-official";
import Highcharts, {
  TooltipFormatterContextObject,
} from "highcharts/highstock";
import { DateTime, Duration } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFetch } from "src/common/fetcher/effects";
import { useFetchAll } from "src/common/fetcher/useFetchAll";
import { ChartStateV2, getChartGranularityV2 } from "src/ui/utils/chartUtils";

interface PlatformBatteryMetricsComboPowerChartProps {
  batteryId: number;
  propertyId?: number;
  height?: number;
  chartState: ChartStateV2;
  setChartState: React.Dispatch<React.SetStateAction<ChartStateV2>>;
  timezone?: string;
  timezoneLabel?: string;
  lastMetricTimestamp?: string;
}

export default function PlatformBatteryMetricsComboPowerChart({
  batteryId,
  propertyId,
  height = 320,
  chartState,
  setChartState,
  timezone,
  timezoneLabel,
  lastMetricTimestamp,
}: PlatformBatteryMetricsComboPowerChartProps) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const [isLoading, setIsLoading] = useState(true);

  const fetchUrlMetrics = useMemo(() => {
    if (
      chartState.start &&
      chartState.end &&
      chartState.start <= DateTime.now().setZone(timezone || "system")
    ) {
      return `/api/devices/${
        chartState.granularity
      }/battery-metrics/?battery=${batteryId}&utc_time__gte=${chartState.start
        .toUTC()
        .toISO({ includeOffset: false })}&utc_time__lte=${chartState.end
        .toUTC()
        .toISO({ includeOffset: false })}&ordering=utc_time`;
    }
    return null;
  }, [batteryId, chartState, timezone]);

  const fetchUrlForecasts = useMemo(() => {
    if (
      chartState.start &&
      chartState.end &&
      (!lastMetricTimestamp ||
        (lastMetricTimestamp &&
          chartState.end > DateTime.fromISO(lastMetricTimestamp)))
    ) {
      return `/api/devices/${
        chartState.granularity
      }/battery-forecasts/?battery=${batteryId}&utc_time__gte=${chartState.start
        .toUTC()
        .toISO({ includeOffset: false })}&utc_time__lte=${chartState.end
        .toUTC()
        .toISO({ includeOffset: false })}&ordering=utc_time`;
    }
    return null;
  }, [batteryId, chartState, lastMetricTimestamp]);

  const fetchUrlPrices = useMemo(() => {
    if (
      propertyId &&
      chartState.start &&
      chartState.end &&
      chartState.start <= DateTime.now().setZone(timezone || "system")
    ) {
      return `/api/prices/?related_property=${propertyId}&start_time=${chartState.start
        .toUTC()
        .toISO({ includeOffset: false })}&end_time=${chartState.end
        .toUTC()
        .toISO({
          includeOffset: false,
        })}&ordering=utc_time`;
    }
    return null;
  }, [propertyId, chartState, timezone]);

  const { data: metricData, isLoading: isLoadingMetrics } = useFetch<{
    results: BatteryMetrics1h[];
  }>(fetchUrlMetrics);

  const { data: forecastData, isLoading: isLoadingForecasts } = useFetch<{
    results: BatteryMetrics1h[];
  }>(fetchUrlForecasts);

  const { data: priceData, isLoading: isLoadingPrices } =
    useFetchAll<Price>(fetchUrlPrices);

  const dataLoaded = useMemo(() => {
    return (
      (!fetchUrlMetrics || (fetchUrlMetrics && metricData)) &&
      (!fetchUrlForecasts || (fetchUrlForecasts && forecastData)) &&
      (!fetchUrlPrices || (fetchUrlPrices && priceData))
    );
  }, [
    fetchUrlForecasts,
    fetchUrlMetrics,
    fetchUrlPrices,
    forecastData,
    metricData,
    priceData,
  ]);

  const noDataAvailable = useMemo(() => {
    return fetchUrlMetrics === null && fetchUrlForecasts === null;
  }, [fetchUrlForecasts, fetchUrlMetrics]);

  useEffect(() => {
    if (noDataAvailable) {
      setIsLoading(false);
    } else if (dataLoaded && chartRef.current?.chart) {
      const chart = chartRef.current.chart;

      const metrics = metricData
        ? metricData.results.map((item: BatteryMetrics1h) => {
            return [
              DateTime.fromISO(item.utc_time, {
                zone: "utc",
              }).toMillis(),
              item,
            ];
          })
        : [];

      const lastMetricTimestamp =
        metrics.length > 0 ? metrics[metrics.length - 1][0] ?? 0 : 0;

      const forecasts = forecastData
        ? forecastData.results
            .map((item: BatteryMetrics1h) => {
              return [
                DateTime.fromISO(item.utc_time, {
                  zone: "utc",
                }).toMillis(),
                item,
              ];
            })
            .filter((item) => item[0] && item[0] > lastMetricTimestamp)
        : ([] as any);

      const data = [...metrics, ...forecasts]
        .sort((a, b) => a[0] - b[0])
        .reduce((acc: [number, BatteryMetrics1h][], curr) => {
          const existingIndex = acc.findIndex((item) => item[0] === curr[0]);
          if (existingIndex !== -1) {
            // If timestamp already exists, keep the value from metrics
            if (metrics.some((item) => item[0] === curr[0])) {
              return acc;
            }
            // Otherwise, update with the new value only if it's after the last metric
            if (curr[0] > lastMetricTimestamp) {
              acc[existingIndex] = curr;
            }
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);

      // Create padded data set
      const paddedData: [number, BatteryMetrics1h | null][] = [];
      if (chartState.start && chartState.end) {
        let currentTime = chartState.start;
        const endTime = chartState.end;

        // Adjust the start time based on granularity
        switch (chartState.granularity) {
          case "15m":
            currentTime = currentTime
              .startOf("hour")
              .plus({ minutes: Math.floor(currentTime.minute / 15) * 15 });
            break;
          case "30m":
            currentTime = currentTime
              .startOf("hour")
              .plus({ minutes: Math.floor(currentTime.minute / 30) * 30 });
            break;
          case "1h":
            currentTime = currentTime.startOf("hour");
            break;
          case "1d":
            currentTime = currentTime.startOf("day");
            break;
        }

        const granularityDuration = Duration.fromObject(
          chartState.granularity === "15m"
            ? { minutes: 15 }
            : chartState.granularity === "30m"
            ? { minutes: 30 }
            : chartState.granularity === "1h"
            ? { hours: 1 }
            : { days: 1 }
        );

        while (currentTime <= endTime) {
          paddedData.push([currentTime.toMillis(), null]);
          currentTime = currentTime.plus(granularityDuration);
        }
      }

      // Populate padded data with actual values
      data.forEach(([timestamp, value]) => {
        const index = paddedData.findIndex(([x]) => x === timestamp);
        if (index !== -1) {
          paddedData[index][1] = value;
        }
      });

      // Update the data
      chart.series[0].setData(
        paddedData.map((item) => [item[0], item[1]?.soc]),
        false
      );
      chart.series[1].setData(
        paddedData.map((item) => [item[0], item[1]?.battery_power_in_kw]),
        false
      );
      chart.series[2].setData(
        paddedData.map((item) => [item[0], item[1]?.solar_power_in_kw]),
        false
      );
      chart.series[3].setData(
        paddedData.map((item) => [item[0], item[1]?.grid_power_in_kw]),
        false
      );
      chart.series[4].setData(
        paddedData.map((item) => [
          item[0],
          item[1]?.property_energy_consumption_in_kwh,
        ]),
        false
      );

      const importPrices = priceData?.filter(
        (p) => p.is_export_price === false
      );
      const exportPrices = priceData?.filter((p) => p.is_export_price === true);

      if (importPrices?.length) {
        chart.series[5].setData(
          importPrices.map((item: Price) => {
            return [
              DateTime.fromISO(item.utc_time, {
                zone: "utc",
              }).toMillis(),
              item.price_per_kwh,
            ];
          }),
          false
        );
      }

      if (exportPrices?.length) {
        chart.series[6].setData(
          exportPrices.map((item: Price) => {
            return [
              DateTime.fromISO(item.utc_time, {
                zone: "utc",
              }).toMillis(),
              item.price_per_kwh,
            ];
          }),
          false
        );
      }

      // Set the extremes based on chartState
      if (chartState.start && chartState.end) {
        chart.xAxis[0].setExtremes(
          chartState.start.toMillis(),
          chartState.end.toMillis()
        );
      }

      // Redraw the chart
      chart.redraw();

      setIsLoading(false);
    }
  }, [
    chartState,
    dataLoaded,
    forecastData,
    isLoading,
    isLoadingForecasts,
    isLoadingMetrics,
    isLoadingPrices,
    metricData,
    noDataAvailable,
    priceData,
    timezone,
  ]);

  const chartOptions = useMemo(() => {
    const localNow = DateTime.now().setZone(timezone || "system");
    const localDate = localNow.toMillis();

    return {
      chart: {
        height: height - 65,
        marginRight: 64,
        marginLeft: 64,
        marginTop: 32,
      },
      time: {
        timezone,
        useUTC: timezone !== undefined,
      },
      rangeSelector: {
        enabled: false,
      },
      xAxis: {
        minRange: 60 * 1000,
        crosshair: true,
        title: {
          y: 5,
          text: `Time<br><span style="font-size: 11px; color: #999;">${timezoneLabel}</span>`,
        },
        plotLines: [
          {
            color: "#544fc5",
            width: 2,
            value: localNow.toMillis(),
          },
        ],
        events: {
          afterSetExtremes: function (e: any) {
            const chart = e.target.chart;

            if (!chart || !e.min || !e.max || e.trigger !== "zoom") {
              return;
            }

            const minDateTime = DateTime.fromMillis(Math.round(e.min))
              .setZone(timezone)
              .startOf("minute");
            const maxDateTime = DateTime.fromMillis(Math.round(e.max))
              .setZone(timezone)
              .startOf("minute");

            const granularity = getChartGranularityV2(minDateTime, maxDateTime);

            setChartState({
              start: minDateTime,
              end: maxDateTime,
              granularity: granularity,
            });
          },
        },
      },
      tooltip: {
        useHTML: true,
        shared: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          const points = this.points;

          if (
            !points ||
            points.length === 0 ||
            typeof points[0].x !== "number"
          ) {
            return "";
          }

          const pointDate = DateTime.fromMillis(points[0].x).setZone(
            timezone || "system"
          );

          const dateStr = pointDate.toFormat(
            `cccc, dd LLLL ${pointDate.toFormat("HH:mm")}`
          );

          let tooltipContent = `<div style="font-size: 12px; margin-bottom: 5px;">${dateStr}</div>`;

          points.forEach((point) => {
            if (point.series.visible && typeof point.y === "number") {
              const seriesName = point.series.name;
              const bulletPoint = `<span style="color:${point.color};">\u25CF</span>`;

              if (
                point.series.options.id &&
                parseInt(point.series.options.id) === 1
              ) {
                const valueStr = `${point.y.toFixed(1)} %`;
                tooltipContent += `<div>${bulletPoint} ${seriesName}: <b>${valueStr}</b></div>`;
              } else if (
                point.series.options.id &&
                (parseInt(point.series.options.id) === 6 ||
                  parseInt(point.series.options.id) === 7)
              ) {
                const valueStr = `£${point.y.toFixed(2)}`;
                tooltipContent += `<div>${bulletPoint} ${seriesName}: <b>${valueStr}</b></div>`;
              } else {
                const valueStr = `${point.y.toFixed(1)} kW`;
                tooltipContent += `<div>${bulletPoint} ${seriesName}: <b>${valueStr}</b></div>`;
              }
            }
          });

          return tooltipContent;
        },
        style: {
          fontFamily: "Barlow, sans-serif",
        },
      },
      yAxis: [
        {
          title: {
            text: "Power (kW)",
          },
          tickAmount: 5,
          endOnTick: true,
          alignTicks: true,
          allowDecimals: false,
        },
        {
          opposite: true,
          title: {
            text: "Price (£/kWh)",
          },
          tickAmount: 5,
          endOnTick: true,
          alignTicks: true,
          labels: {
            format: "{value:.2f}",
          },
        },
        {
          opposite: true,
          endOnTick: true,
          visible: false,
          min: 0,
          max: 100,
          tickAmount: 5,
          alignTicks: true,
          title: {
            text: "Percentage (%)",
          },
        },
      ],
      series: [
        {
          id: 1,
          type: "areaspline",
          lineWidth: 0,
          color: "rgba(156,39,176)",
          fillOpacity: 0.25,
          name: "Battery level",
          dataGrouping: {
            enabled: false,
          },
          yAxis: 2,
          zIndex: 0,
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgb(156,39,176)",
            },
            {
              color: "rgb(156,39,176,0.5)",
              fillColor: "rgba(156,39,176,0.3)",
              dashStyle: "ShortDash",
            },
          ],
        },
        {
          id: 2,
          type: "spline",
          lineWidth: 1,
          color: "rgb(200,39,176)",
          name: "Battery power",
          dataGrouping: {
            enabled: false,
          },
          yAxis: 0,
          zIndex: 1,
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgb(200,39,176)",
            },
            {
              color: "rgb(200,39,176,0.5)",
              fillColor: "rgba(200,39,176,0.3)",
              dashStyle: "ShortDash",
            },
          ],
        },
        {
          id: 3,
          type: "spline",
          lineWidth: 1,
          color: "rgb(255,235,59)",
          name: "Solar power",
          dataGrouping: {
            enabled: false,
          },
          yAxis: 0,
          zIndex: 1,
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgb(255, 235, 59)",
            },
            {
              color: "rgb(255, 235, 59,0.5)",
              fillColor: "rgba(255, 235, 59,0.3)",
              dashStyle: "ShortDash",
            },
          ],
        },
        {
          id: 4,
          type: "spline",
          lineWidth: 1,
          color: "rgb(33,150,243)",
          name: "Grid power",
          dataGrouping: {
            enabled: false,
          },
          yAxis: 0,
          zIndex: 1,
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgb(33,150,243)",
            },
            {
              color: "rgb(33,150,243,0.5)",
              fillColor: "rgba(33,150,243,0.3)",
              dashStyle: "ShortDash",
            },
          ],
        },
        {
          id: 5,
          type: "spline",
          lineWidth: 1,
          color: "rgb(0,163,150)",
          name: "Home power",
          dataGrouping: {
            enabled: false,
          },
          yAxis: 0,
          zIndex: 1,
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgb(30,163,150)",
            },
            {
              color: "rgb(0,163,150,0.5)",
              fillColor: "rgba(0,163,150,0.3)",
              dashStyle: "ShortDash",
            },
          ],
        },
        {
          id: 6,
          type: "line",
          step: true,
          lineWidth: 2,
          name: "Import price/kWh",
          color: "rgb(219, 44, 111)",
          dataGrouping: {
            enabled: false,
          },
          yAxis: 1,
          zIndex: 2,
          turboThreshold: 10000,
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgb(219, 44, 111)",
            },
            {
              color: "rgb(219, 44, 111)",
              fillColor: "rgba(219, 44, 111)",
              dashStyle: "ShortDash",
            },
          ],
        },
        {
          id: 7,
          type: "line",
          step: true,
          lineWidth: 2,
          name: "Export price/kWh",
          color: "rgb(78, 13, 38)",
          dataGrouping: {
            enabled: false,
          },
          yAxis: 1,
          zIndex: 2,
          turboThreshold: 10000,
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgb(78, 13, 38)",
            },
            {
              color: "rgb(78, 13, 38,0.5)",
              fillColor: "rgba(78, 13, 38,0.3)",
              dashStyle: "ShortDash",
            },
          ],
        },
      ],
      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        y: 12,
        padding: 0,
        symbolWidth: 14,
        itemStyle: {
          color: "white",
          fontFamily: "Barlow",
          cursor: "pointer",
        },
        itemHoverStyle: {
          color: "white",
          fontFamily: "Barlow",
          cursor: "pointer",
        },
      },
      loading: {
        labelStyle: {
          color: "transparent",
        },
        style: { backgroundColor: "transparent" },
      },
      plotOptions: {
        column: {
          maxPointWidth: 20,
        },
        series: {
          pointPlacement: "on",
        },
      },
    };
  }, [timezone, height, timezoneLabel, setChartState]);

  return (
    <div
      style={{
        height: height,
        backgroundColor: "rgb(37, 42, 49)",
        borderRadius: 4,
        padding: 20,
        color: "white",
      }}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontSize: 21,
              marginBottom: 10,
            }}
          >
            <b>Combined View</b>
          </div>
        </div>
      </div>
      <div>
        {isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: height - 100,
            }}
          >
            <Spinner />
          </div>
        )}
        {noDataAvailable ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 260,
              width: "100%",
            }}
          >
            No data available
          </div>
        ) : (
          <div
            style={{
              visibility: isLoading ? "hidden" : "visible",
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={chartOptions}
              ref={chartRef}
            />
            {(isLoadingMetrics || isLoadingForecasts || isLoadingPrices) && (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  height: height - 70,
                  marginTop: 45 - height,
                }}
              >
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
