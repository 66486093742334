import { Spinner } from "@blueprintjs/core";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import HighchartsReact from "highcharts-react-official";
import Highcharts, {
  TooltipFormatterContextObject,
} from "highcharts/highstock";
import { DateTime } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import { PropertyDailyMetrics } from "../../../__generated__/types/PropertyDailyMetrics";
import { useFetch } from "../../../common/fetcher/effects";
import { usePropertyTimezone } from "../properties/properties";

interface PlatformPropertySavingsBarChartWidgetProps {
  propertyId: number;
  height: number;
}

type Granularity = "day" | "month";

export default function PlatformPropertySavingsBarChartWidget({
  propertyId,
  height,
}: PlatformPropertySavingsBarChartWidgetProps) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [granularity, setGranularity] = useState<Granularity>("month");

  const { timezone } = usePropertyTimezone(propertyId);

  const handleGranularityChange = (event: SelectChangeEvent) => {
    setGranularity(event.target.value as Granularity);
    setIsInitialLoad(true);
  };

  const { data, isLoading } = useFetch<{ results: PropertyDailyMetrics[] }>(
    `/api/property/daily-metrics/?property=${propertyId}`
  );

  const [
    actualCost,
    estimatedCostNoBattery,
    estimatedCostNoAlgo,
    estimatedCostNoAlgo2,
  ] = useMemo(() => {
    const aggregateByMonth = (data: PropertyDailyMetrics[]) => {
      const monthlyData: { [key: string]: PropertyDailyMetrics } = {};

      data.forEach((item) => {
        const monthKey = DateTime.fromISO(item.date, {
          zone: timezone,
        }).toFormat("yyyy-MM");
        if (!monthlyData[monthKey]) {
          monthlyData[monthKey] = { ...item, date: monthKey };
        } else {
          monthlyData[monthKey].actual_cost =
            (monthlyData[monthKey].actual_cost || 0) + (item.actual_cost || 0);
          monthlyData[monthKey].cost_without_battery =
            (monthlyData[monthKey].cost_without_battery || 0) +
            (item.cost_without_battery || 0);
          monthlyData[monthKey].cost_without_algorithm =
            (monthlyData[monthKey].cost_without_algorithm || 0) +
            (item.cost_without_algorithm || 0);
          monthlyData[monthKey].cost_without_algorithm2 =
            (monthlyData[monthKey].cost_without_algorithm2 || 0) +
            (item.cost_without_algorithm2 || 0);
        }
      });

      return Object.values(monthlyData);
    };

    if (!data?.results) {
      return [[], [], [], []];
    }

    const processedData =
      granularity === "month" ? aggregateByMonth(data.results) : data.results;

    const sortData = (data: [number, number][]) =>
      data.sort((a, b) => a[0] - b[0]);

    const actualCost = sortData(
      processedData.map((p) => [
        DateTime.fromISO(p.date, { zone: timezone }).toMillis(),
        Math.round((p.actual_cost ?? 0) * 100) / 100,
      ])
    );

    const estimatedCostNoBattery = sortData(
      processedData.map((p) => [
        DateTime.fromISO(p.date, { zone: timezone }).toMillis(),
        Math.round((p.cost_without_battery ?? 0) * 100) / 100,
      ])
    );

    const estimatedCostNoAlgo = sortData(
      processedData.map((p) => [
        DateTime.fromISO(p.date, { zone: timezone }).toMillis(),
        Math.round((p.cost_without_algorithm ?? 0) * 100) / 100,
      ])
    );

    const estimatedCostNoAlgo2 = sortData(
      processedData.map((p) => [
        DateTime.fromISO(p.date, { zone: timezone }).toMillis(),
        Math.round((p.cost_without_algorithm2 ?? 0) * 100) / 100,
      ])
    );

    return [
      actualCost,
      estimatedCostNoBattery,
      estimatedCostNoAlgo,
      estimatedCostNoAlgo2,
    ];
  }, [data?.results, timezone, granularity]);

  const hasDataForSelectedGranularity = useMemo(() => {
    return (
      actualCost.length ||
      estimatedCostNoBattery.length ||
      estimatedCostNoAlgo.length ||
      estimatedCostNoAlgo2.length
    );
  }, [
    actualCost.length,
    estimatedCostNoBattery.length,
    estimatedCostNoAlgo.length,
    estimatedCostNoAlgo2.length,
  ]);

  const [fullRangeStart, fullRangeEnd] = useMemo(() => {
    if (!hasDataForSelectedGranularity) {
      return [null, null];
    }
    const allData = [
      ...actualCost,
      ...estimatedCostNoBattery,
      ...estimatedCostNoAlgo,
      ...estimatedCostNoAlgo2,
    ];
    return [
      Math.min(...allData.map((point) => point[0])),
      Math.max(...allData.map((point) => point[0])),
    ];
  }, [
    hasDataForSelectedGranularity,
    actualCost,
    estimatedCostNoBattery,
    estimatedCostNoAlgo,
    estimatedCostNoAlgo2,
  ]);

  // Set initial extremes after dataset has loaded
  useEffect(() => {
    if (
      isInitialLoad &&
      !isLoading &&
      hasDataForSelectedGranularity &&
      chartRef.current
    ) {
      const chart = chartRef.current.chart;

      // Initially show the last 7 days
      if (fullRangeEnd) {
        const start =
          fullRangeEnd - (granularity === "day" ? 8 : 60) * 24 * 60 * 60 * 1000;
        const end =
          fullRangeEnd + (granularity === "day" ? 1 : 15) * 24 * 60 * 60 * 1000;
        chart.xAxis[0].setExtremes(start, end);
      }

      setIsInitialLoad(false);
    }
  }, [
    fullRangeEnd,
    fullRangeStart,
    granularity,
    hasDataForSelectedGranularity,
    isInitialLoad,
    isLoading,
    timezone,
  ]);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        height: height - 65,
        marginRight: 20,
        marginLeft: 64,
        panning: {
          enabled: false,
        },
        zooming: {
          type: false,
        },
      },
      time: {
        timezone,
        useUTC: timezone !== undefined,
      },
      xAxis: {
        minRange:
          granularity === "day"
            ? 5 * 24 * 60 * 60 * 1000
            : 90 * 24 * 60 * 60 * 1000,
        minTickInterval: 2 * 24 * 60 * 60 * 1000,
        tickInterval:
          granularity === "day" ? undefined : 30 * 24 * 60 * 60 * 1000,
        crosshair: true,
        dateTimeLabelFormats:
          granularity === "day"
            ? {
                millisecond: "%e %b",
                second: "%e %b",
                minute: "%e %b",
                hour: "%e %b",
                day: "%e %b",
                week: "%e %b",
                month: "%e %b",
                year: "%e %b",
              }
            : {
                millisecond: "%b %Y",
                second: "%b %Y",
                minute: "%b %Y",
                hour: "%b %Y",
                day: "%b %Y",
                week: "%b %Y",
                month: "%b %Y",
                year: "%b %Y",
              },
        title: {
          text: granularity === "day" ? "Date" : "Month",
          style: {
            fontFamily: "Barlow",
            color: "white",
          },
        },
        labels: {
          style: {
            fontFamily: "Barlow",
            color: "white",
          },
          format: granularity === "day" ? "{value:%e %b}" : "{value:%b %Y}",
        },
      },
      yAxis: {
        title: {
          text: "Energy Cost (£)",
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        borderColor: "#999",
        formatter: function (this: TooltipFormatterContextObject): string {
          const points = this.points;

          if (
            !points ||
            points.length === 0 ||
            typeof points[0].x !== "number"
          ) {
            return "";
          }

          const pointDate = DateTime.fromMillis(points[0].x).setZone(
            timezone || "system"
          );

          const dateStr = pointDate.toFormat(
            granularity === "day" ? "cccc, dd LLLL" : "LLLL yyyy"
          );

          let tooltipContent = `<div style="font-size: 12px; margin-bottom: 5px;">${dateStr}</div>`;

          points.forEach((point) => {
            if (point.series.visible && typeof point.y === "number") {
              const seriesName = point.series.name;
              const valueStr = `£${point.y.toFixed(2)}`;
              const bulletPoint = `<span style="color:${point.color};">\u25CF</span>`;
              tooltipContent += `<div>${bulletPoint} ${seriesName}: <b>${valueStr}</b></div>`;
            }
          });

          return tooltipContent;
        },
        style: {
          fontFamily: "Barlow, sans-serif",
        },
      },
      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        y: 5,
        padding: 0,
        itemStyle: {
          color: "white",
          fontFamily: "Barlow",
          cursor: "pointer",
        },
        itemHoverStyle: {
          color: "#aaa",
          fontFamily: "Barlow",
          cursor: "pointer",
        },
      },
      series: [
        {
          name: "Cost without battery (£)",
          type: "column",
          color: "#DB2C6F",
          showInLegend: true,
          borderWidth: 0,
          data: estimatedCostNoBattery,
        },

        {
          name: "Cost without algorithm2 (£)",
          type: "column",
          color: "blue",
          showInLegend: true,
          borderWidth: 0,
          data: estimatedCostNoAlgo2,
        },
        {
          name: "Actual Cost (£)",
          type: "column",
          color: "#8EB125",
          showInLegend: true,
          borderWidth: 0,
          data: actualCost,
        },
      ],
      navigator: {
        series: {
          dataGrouping: {
            enabled: false,
          },
        },
      },
      rangeSelector: {
        buttons: [],
      },
      plotOptions: {
        column: {
          maxPointWidth: granularity === "day" ? undefined : 20,
        },
      },
    };
  }, [
    height,
    timezone,
    granularity,
    estimatedCostNoBattery,
    estimatedCostNoAlgo2,
    actualCost,
  ]);

  return (
    <div
      style={{
        height: height,
        backgroundColor: "rgb(37, 42, 49)",
        borderRadius: 4,
        padding: 20,
        color: "white",
      }}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontSize: 21,
              marginBottom: 10,
            }}
          >
            <b>Cost comparison</b>
          </div>
          <div
            style={{
              width: "100%",
              flex: 1,
              textAlign: "right",
              fontSize: "16px",
              marginBottom: 10,
              color: "rgba(255,255,255,.75)",
              marginTop: 4,
            }}
          >
            <FormControl sx={{ width: 140 }} disabled={isLoading}>
              <Select
                hidden={true}
                id="chart-granularity-select"
                value={granularity}
                onChange={handleGranularityChange}
                sx={{
                  height: 26,
                  fontSize: "0.875rem",
                  "& .MuiSelect-select": {
                    paddingTop: "3px",
                    paddingBottom: "3px",
                    textAlign: "left",
                  },
                  "& .MuiOutlinedInput-input": {
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      "& .MuiMenu-list": {
                        padding: "2px 0",
                      },
                    },
                  },
                }}
              >
                <MenuItem
                  value="day"
                  sx={{
                    height: 26,
                    fontSize: "0.875rem",
                    padding: "4px 10px",
                  }}
                >
                  Daily
                </MenuItem>
                <MenuItem
                  value="month"
                  sx={{
                    height: 26,
                    fontSize: "0.875rem",
                    padding: "4px 10px",
                  }}
                >
                  Monthly
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div>
        {isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: height - 100,
            }}
          >
            <Spinner />
          </div>
        )}
        {hasDataForSelectedGranularity || isLoading ? (
          <div
            style={{
              visibility: isLoading ? "hidden" : "visible",
            }}
          >
            <HighchartsReact
              key={granularity}
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={chartOptions}
              ref={chartRef}
            />
          </div>
        ) : (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 260,
              width: "100%",
            }}
          >
            No data available
          </div>
        )}
      </div>
    </div>
  );
}
