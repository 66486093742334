import { Icon, Popover } from "@blueprintjs/core";
import { Battery } from "@ec1/types/Battery";
import {
  BatteryStd,
  ElectricBike,
  ElectricCar,
  Forest,
  Home,
  PowerOff,
  WbSunny,
} from "@mui/icons-material";
import React, { useMemo } from "react";
import {
  PiBatteryChargingVerticalDuotone,
  PiBatteryVerticalEmptyDuotone,
  PiBatteryVerticalFullDuotone,
  PiBatteryVerticalHighDuotone,
  PiBatteryVerticalLowDuotone,
  PiBatteryVerticalMediumDuotone,
  PiBatteryWarningVerticalDuotone,
} from "react-icons/pi";
import { createUseStyles } from "react-jss";
import { Handle, Position, useStore } from "reactflow";
import "reactflow/dist/style.css";
import { EnergyUtils } from "src/ui/utils/chartUtils";
import { getBatteryIconColor } from "../PlatformSiteDeviceMapView.react";

// Constants
export const ZOOM_THRESHOLD = 0.65;
export const NODE_SIZE = 180;
export const CHILD_SIZE = 48;
export const MIN_ROOT_SIZE = 100;
export const MAX_ROOT_SIZE = 200;

export const SOURCE_TOP_HANDLE_ID = "1";
export const TARGET_TOP_HANDLE_ID = "11";

export const SOURCE_BOTTOM_HANDLE_ID = "2";
export const TARGET_BOTTOM_HANDLE_ID = "12";

export const SOURCE_LEFT_HANDLE_ID = "3";
export const TARGET_LEFT_HANDLE_ID = "13";

export const SOURCE_RIGHT_HANDLE_ID = "4";
export const TARGET_RIGHT_HANDLE_ID = "14";

export function calculateRootSize(zoomLevel: number): number {
  return Math.min(MIN_ROOT_SIZE / zoomLevel, MAX_ROOT_SIZE);
}

export interface GridNodeData {
  label: string;
  icon: string;
  color: string;
  isGroup: boolean;
  isChild: boolean;
  isRoot: boolean;
  isOffline: boolean;
  battery?: Battery;
  tooltipContent?: JSX.Element;
  importValue?: number;
  exportValue?: number;
}

interface GridNodeStylesProps {
  size: number;
  isOffline: boolean;
  color: string;
  isChild: boolean;
  isRoot: boolean;
  scale: number;
  isGroup: boolean;
}

interface RootDisplayValueProps {
  importValue?: number;
  exportValue?: number;
  scale: number;
}

const components: Record<string, React.ComponentType<any>> = {
  solar: WbSunny,
  home: Home,
  forest: Forest,
  bike: ElectricBike,
  car: ElectricCar,
  battery: BatteryStd,
};

const useGridNodeStyles = createUseStyles({
  nodeBase: {
    border: ({ isOffline, color }: GridNodeStylesProps) =>
      `2px solid ${isOffline ? "#404854" : color}`,
    padding: 10,
    borderRadius: "50%",
    width: ({ size }: GridNodeStylesProps) => size,
    height: ({ size }: GridNodeStylesProps) => size,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    outline: "none",
  },
  groupZoomed: {
    width: NODE_SIZE,
    height: NODE_SIZE,
    border: "none",
  },
  handle: {
    visibility: "hidden",
  },
  handleTop: {
    top: 0,
  },
  handleBottom: {
    bottom: 0,
  },
  handleLeft: {
    left: 0,
  },
  handleRight: {
    right: 0,
  },
  innerContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    opacity: ({ isOffline }: GridNodeStylesProps) => (isOffline ? 0.5 : 1),
  },
  batteryContainer: {
    display: "flex",
    flexDirection: "row",
    height: 10,
  },
  batteryIcon: {
    height: 10,
    marginTop: -4,
    marginRight: 1,
    marginLeft: -1,
  },
  batteryLevel: {
    height: 10,
    marginTop: 0,
    fontSize: 8,
    fontWeight: 500,
    fontFamily: "Barlow",
  },
  label: {
    fontFamily: "Barlow",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ({ isChild, isRoot, scale }: GridNodeStylesProps) =>
      isChild ? 4 : isRoot ? 16 * scale : 10,
    fontSize: ({ isChild, isRoot, scale }: GridNodeStylesProps) =>
      isChild ? 9 : isRoot ? 40 * scale : 30,
    color: ({ isOffline, isGroup }: GridNodeStylesProps) =>
      isOffline && isGroup ? "rgba(205, 66, 70, 0.8)" : "#f3f4f6",
    opacity: ({ isOffline, isGroup }: GridNodeStylesProps) =>
      isOffline && !isGroup ? 0.5 : 1,
    flexDirection: ({ isRoot }: GridNodeStylesProps) =>
      isRoot ? "column" : "row",
  },
  smallIcon: {
    fontSize: 12,
    // Needed for MUI icons
    width: "12px !important",
    height: "12px !important",
  },
  largeIcon: {
    fontSize: 48,
    // Needed for MUI icons
    width: "48px !important",
    height: "48px !important",
  },
  powerOff: {
    fontSize: ({ isChild }: GridNodeStylesProps) => (isChild ? 9 : 30),
    marginRight: ({ isChild }: GridNodeStylesProps) => (isChild ? 0 : 2),
    // Needed for MUI icons
    width: ({ isChild }: GridNodeStylesProps) =>
      isChild ? "9px !important" : "30px !important",
    height: ({ isChild }: GridNodeStylesProps) =>
      isChild ? "9px !important" : "30px !important",
  },
});

interface RootDisplayValueStylesProps {
  scale: number;
}

const useRootDisplayValueStyles = createUseStyles({
  tooltipContent: {
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    flexDirection: "column",
    padding: 8,
  },
  tooltipRow: {
    display: "flex",
    alignItems: "center",
  },
  tooltipRowMarginTop: {
    marginTop: 8,
  },
  tooltipLabel: {
    fontSize: 14,
    width: 125,
    fontFamily: "Barlow",
  },
  tooltipValue: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Barlow",
  },
  displayContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: ({ scale }: RootDisplayValueStylesProps) => 2 * scale,
    marginLeft: -1,
  },
  displayRow: {
    display: "flex",
    alignItems: "center",
  },
  displayRowSecond: {
    extend: "displayRow",
    marginTop: 2,
  },
  displayIcon: {
    marginTop: ({ scale }: RootDisplayValueStylesProps) => scale,
    marginRight: ({ scale }: RootDisplayValueStylesProps) => 4 * scale,
  },
  tooltipDisplayIcon: {
    marginTop: 1,
    marginRight: 4,
  },
  tooltipText: {
    fontSize: ({ scale }: RootDisplayValueStylesProps) => 32 * scale,
  },
});

const RootDisplayValue: React.FC<RootDisplayValueProps> = React.memo(
  ({ importValue, exportValue, scale }) => {
    const classes = useRootDisplayValueStyles({ scale });

    const importHumanized = useMemo(
      () => EnergyUtils.humanize(importValue ?? 0, "w"),
      [importValue]
    );
    const exportHumanized = useMemo(
      () => EnergyUtils.humanize(exportValue ?? 0, "w"),
      [exportValue]
    );

    const tooltipContent = useMemo(
      () => (
        <div className={classes.tooltipContent}>
          <div className={classes.tooltipRow}>
            <Icon
              icon="arrow-left"
              size={14}
              color="rgb(201, 28, 37)"
              className={classes.tooltipDisplayIcon}
            />
            <span className={classes.tooltipLabel}>Total Import Power:</span>
            <span className={classes.tooltipValue}>
              <b>{importHumanized}</b>
            </span>
          </div>
          <div
            className={`${classes.tooltipRow} ${classes.tooltipRowMarginTop}`}
          >
            <Icon
              icon="arrow-right"
              size={14}
              color="rgb(25, 189, 60)"
              className={classes.tooltipDisplayIcon}
            />
            <span className={classes.tooltipLabel}>Total Export Power:</span>
            <span className={classes.tooltipValue}>
              <b>{exportHumanized}</b>
            </span>
          </div>
        </div>
      ),
      [classes, importHumanized, exportHumanized]
    );

    const displayContent = useMemo(
      () => (
        <div className={classes.displayContent}>
          <div className={classes.displayRow}>
            <Icon
              icon="arrow-left"
              size={28 * scale}
              color="rgb(201, 28, 37)"
              className={classes.displayIcon}
            />
            <span className={classes.tooltipText}>{importHumanized}</span>
          </div>
          <div className={classes.displayRowSecond}>
            <Icon
              icon="arrow-right"
              size={28 * scale}
              color="rgb(25, 189, 60)"
              className={classes.displayIcon}
            />
            <span className={classes.tooltipText}>{exportHumanized}</span>
          </div>
        </div>
      ),
      [classes, importHumanized, exportHumanized, scale]
    );

    return (
      <Popover interactionKind="hover" content={tooltipContent}>
        {displayContent}
      </Popover>
    );
  }
);

export function getBatteryIcon(
  batteryLevel: number,
  status: string | undefined,
  stationState: string | undefined
) {
  const color = getBatteryIconColor(batteryLevel, status, stationState);

  if (stationState === "FAULT") {
    return <PiBatteryWarningVerticalDuotone size={11} color={color} />;
  }

  if (status === "offline") {
    return <PiBatteryWarningVerticalDuotone size={11} color={color} />;
  }

  if (status && status.toLowerCase() === "charging") {
    return <PiBatteryChargingVerticalDuotone size={11} color={color} />;
  }

  if (batteryLevel === 100) {
    return <PiBatteryVerticalFullDuotone size={11} color={color} />;
  }

  if (batteryLevel >= 80) {
    return <PiBatteryVerticalHighDuotone size={11} color={color} />;
  }

  if (batteryLevel >= 30) {
    return <PiBatteryVerticalMediumDuotone size={11} color={color} />;
  }

  if (batteryLevel > 0) {
    return <PiBatteryVerticalLowDuotone size={11} color={color} />;
  }

  return <PiBatteryVerticalEmptyDuotone size={11} color={color} />;
}

const GridNode: React.FC<{ data: GridNodeData }> = ({ data }) => {
  const {
    label,
    icon,
    color,
    isGroup,
    isChild,
    isRoot,
    isOffline,
    battery,
    tooltipContent,
    importValue,
    exportValue,
  } = data;

  // Calculate the root size according to the zoom level
  const zoomLevel = useStore((store) => store.transform[2]);
  const rootSize = useMemo(() => calculateRootSize(zoomLevel), [zoomLevel]);

  const isZoomed = useMemo(() => zoomLevel >= ZOOM_THRESHOLD, [zoomLevel]);

  // Calculate the scale to be used for root icon/font sizes
  const scale = useMemo(
    () => rootSize / (MAX_ROOT_SIZE + (MAX_ROOT_SIZE - NODE_SIZE)),
    [rootSize]
  );

  const classes = useGridNodeStyles({
    size: isChild ? CHILD_SIZE : isRoot ? rootSize : NODE_SIZE,
    isOffline,
    color,
    isChild,
    isRoot,
    scale,
    isGroup,
  });

  const IconComponent = useMemo(() => {
    return components[icon as keyof typeof components];
  }, [icon]);

  const displayValue = useMemo(() => {
    if (isOffline && isGroup) {
      return (
        <>
          <PowerOff className={classes.powerOff} />
          Offline
        </>
      );
    }

    if (isOffline || label === null || label === undefined) {
      return "-";
    }

    const parsedValue = parseInt(label);
    if (!isNaN(parsedValue)) {
      return EnergyUtils.humanize(parsedValue, "w");
    }

    return label;
  }, [isOffline, isGroup, label, classes]);

  if (isChild && !isZoomed) {
    return null;
  }

  if (isGroup && isZoomed) {
    return (
      <Popover
        interactionKind="hover"
        content={tooltipContent}
        disabled={tooltipContent === undefined}
      >
        <div className={`${classes.nodeBase} ${classes.groupZoomed}`} />
      </Popover>
    );
  }

  return (
    <Popover
      interactionKind="hover"
      content={tooltipContent}
      disabled={tooltipContent === undefined}
    >
      <div className={classes.nodeBase}>
        {/* Source Handles */}
        <Handle
          type="source"
          id={SOURCE_TOP_HANDLE_ID}
          position={Position.Top}
          className={`${classes.handle} ${classes.handleTop}`}
        />
        <Handle
          type="source"
          id={SOURCE_BOTTOM_HANDLE_ID}
          position={Position.Bottom}
          className={`${classes.handle} ${classes.handleBottom}`}
        />
        <Handle
          type="source"
          id={SOURCE_LEFT_HANDLE_ID}
          position={Position.Left}
          className={`${classes.handle} ${classes.handleLeft}`}
        />
        <Handle
          type="source"
          id={SOURCE_RIGHT_HANDLE_ID}
          position={Position.Right}
          className={`${classes.handle} ${classes.handleRight}`}
        />

        {/* Target Handles */}
        <Handle
          type="target"
          id={TARGET_TOP_HANDLE_ID}
          position={Position.Top}
          className={`${classes.handle} ${classes.handleTop}`}
        />
        <Handle
          type="target"
          id={TARGET_BOTTOM_HANDLE_ID}
          position={Position.Bottom}
          className={`${classes.handle} ${classes.handleBottom}`}
        />
        <Handle
          type="target"
          id={TARGET_LEFT_HANDLE_ID}
          position={Position.Left}
          className={`${classes.handle} ${classes.handleLeft}`}
        />
        <Handle
          type="target"
          id={TARGET_RIGHT_HANDLE_ID}
          position={Position.Right}
          className={`${classes.handle} ${classes.handleRight}`}
        />

        <div className={classes.innerContent}>
          <div className={classes.iconContainer}>
            {battery ? (
              <div className={classes.batteryContainer}>
                <div className={classes.batteryIcon}>
                  {getBatteryIcon(
                    battery.battery_level ?? 0,
                    battery.battery_status,
                    battery.station_state
                  )}
                </div>
                <div className={classes.batteryLevel}>
                  {battery.battery_level}%
                </div>
              </div>
            ) : icon === "grid" ? (
              <Icon
                icon={isRoot ? "high-voltage-pole" : "low-voltage-pole"}
                size={isRoot ? 52 * scale : 12}
              />
            ) : (
              IconComponent && (
                <IconComponent
                  className={isChild ? classes.smallIcon : classes.largeIcon}
                />
              )
            )}
          </div>
          <div className={classes.label}>
            {isRoot ? (
              <RootDisplayValue
                importValue={importValue}
                exportValue={exportValue}
                scale={scale}
              />
            ) : (
              displayValue
            )}
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default GridNode;
