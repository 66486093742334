import { Button, Popover, Position } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import { DateRangeShortcut } from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { DateRange, DateRangePicker3 } from "@blueprintjs/datetime2";
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  datePickerWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 1,
  },
  datePickerLabel: {
    marginRight: 5,
    marginTop: -2,
  },
  datePickerButton: {
    width: 280,
    "&.bp5-button": {
      backgroundColor: "#49505a !important",
      borderRadius: 4,
      justifyContent: "left",
      "&:hover": {
        backgroundColor: "rgba(143, 153, 168, 0.15) !important",
      },
    },
  },
  datePickerButtonError: {
    composes: ["datePickerButton"],
    border: "1px solid #f17575",
    borderRadius: 3,
    outline: "none",
  },
  datePicker: {
    fontFamily: "Barlow",
  },
});

interface Ec1DateRangePickerProps {
  label?: string;
  fromDate?: DateTime;
  toDate?: DateTime;
  minDate?: DateTime;
  maxDate?: DateTime;
  timezone?: string;
  onChange?: (from: DateTime, to: DateTime) => void;
}

export default function Ec1DateRangePicker({
  label = "Period",
  fromDate,
  toDate,
  minDate,
  maxDate,
  timezone = "system", // Default to system timezone
  onChange,
}: Ec1DateRangePickerProps) {
  const classes = useStyles();

  const [selectedRange, setSelectedRange] = useState<DateRange>([null, null]);
  const [displayText, setDisplayText] = useState<string>("Today");
  const [min, setMin] = useState<Date | undefined>(undefined);
  const [max, setMax] = useState<Date | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toLocal = useCallback((dt: DateTime): Date => {
    return dt.setZone("system", { keepLocalTime: true }).toJSDate();
  }, []);

  const toTimezone = useCallback(
    (dt: Date): DateTime => {
      return DateTime.fromJSDate(dt).setZone(timezone, { keepLocalTime: true });
    },
    [timezone]
  );

  const now = DateTime.now();
  const minDt = min && DateTime.fromJSDate(min);
  const maxDt = max && DateTime.fromJSDate(max);

  const offset =
    DateTime.now().offset - DateTime.now().setZone(timezone).offset;
  const offsetNow = now.minus({ minutes: offset });

  // Convert dates to integer representation (YYYYMMDD)
  const dateToInt = (date: DateTime) =>
    parseInt(
      `${date.year}${date.month.toString().padStart(2, "0")}${date.day
        .toString()
        .padStart(2, "0")}`
    );

  const nowInt = dateToInt(now);
  const offsetNowInt = dateToInt(offsetNow);

  let dayOffset = 0;
  if (nowInt < offsetNowInt) {
    dayOffset = -1;
  } else if (nowInt > offsetNowInt) {
    dayOffset = 1;
  }

  const shortcuts: DateRangeShortcut[] = useMemo(
    () => [
      {
        label: "Today",
        includeTime: true,
        dateRange: [
          now.minus({ days: dayOffset }).startOf("day").toJSDate(),
          now.minus({ days: dayOffset }).endOf("day").toJSDate(),
        ],
      },
      {
        label: "Yesterday",
        includeTime: true,
        dateRange: [
          now
            .minus({ days: 1 + dayOffset })
            .startOf("day")
            .toJSDate(),
          now
            .minus({ days: 1 + dayOffset })
            .endOf("day")
            .toJSDate(),
        ],
      },
      {
        label: "This week",
        includeTime: true,
        dateRange: [
          minDt && now.minus({ days: dayOffset }).startOf("week") < minDt
            ? minDt.toJSDate()
            : now.minus({ days: dayOffset }).startOf("week").toJSDate(),
          maxDt && now.minus({ days: dayOffset }).endOf("week") > maxDt
            ? maxDt.toJSDate()
            : now.minus({ days: dayOffset }).endOf("week").toJSDate(),
        ],
      },
      {
        label: "Last week",
        includeTime: true,
        dateRange: [
          now.minus({ weeks: 1, days: dayOffset }).startOf("week").toJSDate(),
          now.minus({ weeks: 1, days: dayOffset }).endOf("week").toJSDate(),
        ],
      },
      {
        label: "This Month",
        includeTime: true,
        dateRange: [
          minDt && now.minus({ days: dayOffset }).startOf("month") < minDt
            ? minDt.toJSDate()
            : now.minus({ days: dayOffset }).startOf("month").toJSDate(),
          maxDt && now.minus({ days: dayOffset }).endOf("month") > maxDt
            ? maxDt.toJSDate()
            : now.minus({ days: dayOffset }).endOf("month").toJSDate(),
        ],
      },
      {
        label: "Last Month",
        includeTime: true,
        dateRange: [
          now.minus({ months: 1, days: dayOffset }).startOf("month").toJSDate(),
          now.minus({ months: 1, days: dayOffset }).endOf("month").toJSDate(),
        ],
      },
      {
        label: "Last 2 Months",
        includeTime: true,
        dateRange: [
          now.minus({ months: 2, days: dayOffset }).startOf("month").toJSDate(),
          now.minus({ months: 1, days: dayOffset }).endOf("month").toJSDate(),
        ],
      },
      {
        label: "Last 6 Months",
        includeTime: true,
        dateRange: [
          now.minus({ months: 6, days: dayOffset }).startOf("month").toJSDate(),
          now.minus({ months: 1, days: dayOffset }).endOf("month").toJSDate(),
        ],
      },
      {
        label: "This Year",
        includeTime: true,
        dateRange: [
          minDt && now.minus({ days: dayOffset }).startOf("year") < minDt
            ? minDt.toJSDate()
            : now.minus({ days: dayOffset }).startOf("year").toJSDate(),
          maxDt && now.minus({ days: dayOffset }).endOf("year") > maxDt
            ? maxDt.toJSDate()
            : now.minus({ days: dayOffset }).endOf("year").toJSDate(),
        ],
      },
    ],
    [now, minDt, maxDt, dayOffset]
  );

  const isDateRangeEqual = useCallback(
    (range1: DateRange, range2: DateRange): boolean => {
      return (
        range1[0]?.getTime() === range2[0]?.getTime() &&
        range1[1]?.getTime() === range2[1]?.getTime()
      );
    },
    []
  );

  const formatDateLabel = useCallback(
    (range: DateRange): string => {
      if (range[0] && range[1]) {
        // Check if the range matches any shortcut
        for (const shortcut of shortcuts) {
          if (shortcut.dateRange[0] && shortcut.dateRange[1]) {
            if (isDateRangeEqual(range, shortcut.dateRange)) {
              return shortcut.label;
            }
          }
        }

        // If no shortcut match, format the date range
        const fromDate = DateTime.fromJSDate(range[0]);
        const toDate = DateTime.fromJSDate(range[1]);
        return `${fromDate.toFormat(
          "dd/MM/yyyy HH:mm"
        )} \u2013 ${toDate.toFormat("dd/MM/yyyy HH:mm")}`;
      }
      return "No dates selected";
    },
    [shortcuts, isDateRangeEqual]
  );

  // Localize the input dates
  useEffect(() => {
    if (minDate) {
      setMin(toLocal(minDate));
    }
    if (maxDate) {
      setMax(toLocal(maxDate));
    }
    if (fromDate && toDate) {
      const range: DateRange = [toLocal(fromDate), toLocal(toDate)];
      setSelectedRange(range);
    }
  }, [fromDate, maxDate, minDate, onChange, toDate, toLocal]);

  // Update display text when selected range changes
  useEffect(() => {
    if (selectedRange[0] && selectedRange[1]) {
      setDisplayText(formatDateLabel(selectedRange));
    }
  }, [selectedRange, formatDateLabel]);

  // De-localize the selected dates before calling the change handler
  const handleDateChange = useCallback(
    (range: DateRange) => {
      if (range[0] && range[1]) {
        const from = toTimezone(range[0]);
        const to = toTimezone(range[1]);

        if (from.hasSame(to, "day")) {
          if (from >= to) {
            setErrorMessage("Please select a valid date range");
            return;
          }
        }

        setErrorMessage(null);
        setSelectedRange(range);
        onChange?.(from, to);
        setDisplayText(formatDateLabel(range));

        // Check if the date (not just time) has changed
        const oldFrom = selectedRange[0] ? toTimezone(selectedRange[0]) : null;
        const oldTo = selectedRange[1] ? toTimezone(selectedRange[1]) : null;

        const dateChanged =
          !oldFrom ||
          !oldTo ||
          !from.hasSame(oldFrom, "day") ||
          !to.hasSame(oldTo, "day");

        if (dateChanged) {
          setIsOpen(false);
        }
      } else {
        setSelectedRange(range);
      }
    },
    [formatDateLabel, onChange, selectedRange, toTimezone]
  );

  const handleShortcutChange = useCallback(
    (shortcut: DateRangeShortcut) => {
      handleDateChange(shortcut.dateRange);
      setIsOpen(false);
    },
    [handleDateChange]
  );

  const dateRange: DateRange = [
    selectedRange[0] ? new Date(selectedRange[0]) : null,
    selectedRange[1] ? new Date(selectedRange[1]) : null,
  ];

  return (
    <div className={classes.datePickerWrapper}>
      <div className={classes.datePickerLabel}>{label}:</div>
      <Popover
        position={Position.BOTTOM_LEFT}
        isOpen={isOpen}
        onInteraction={(nextOpenState) => {
          if (isOpen) {
            setErrorMessage(null);
          }
          setIsOpen(nextOpenState);
        }}
        content={
          <DateRangePicker3
            value={dateRange}
            onChange={handleDateChange}
            onShortcutChange={handleShortcutChange}
            shortcuts={shortcuts}
            minDate={min}
            maxDate={max}
            timePrecision="minute"
            timePickerProps={{ showArrowButtons: true }}
            allowSingleDayRange
            className={classes.datePicker}
            locale="en-GB" // GB so that Monday is the first week day
          />
        }
      >
        <Button
          minimal
          icon="calendar"
          text={displayText}
          title={(isOpen && errorMessage) || ""}
          className={
            isOpen && errorMessage
              ? classes.datePickerButtonError
              : classes.datePickerButton
          }
        />
      </Popover>
    </div>
  );
}
