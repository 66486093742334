import { Button, ButtonGroup, Intent, MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { PlatformProperty } from "../../../__generated__/types/PlatformProperty";
import { Ec1Toaster } from "../../../ui/components/Ec1Toaster.react";
import { useDevices } from "../devices/devices";
import PlatformPropertyDialog from "./PlatformPropertyDialog.react";
import { useProperties } from "./properties";

interface PlatformPropertyAssignWidgetProps {
  propertyId?: number;
  deviceId?: number;
}

const useStyles = createUseStyles({
  button: {
    width: 240,
    outline: "none",
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Barlow",
    paddingLeft: 2,
  },
  labelEmpty: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Barlow",
    opacity: 0.3,
  },
  iconButton: {
    outline: "none",
  },
  selectError: {
    border: "1px solid #f17575",
    borderRadius: 3,
  },
  validationError: {
    color: "#f17575",
    height: 10,
    marginTop: 2,
    marginLeft: 1,
  },
});

const showToast = (
  message: string,
  intent: Intent,
  buttonText?: string,
  buttonOnClick?: () => void
) => {
  Ec1Toaster.show({
    timeout: 5000,
    message: (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: 18,
            fontFamily: "Barlow",
          }}
        >
          <div style={{ marginRight: 15, height: 20 }}>{message}</div>
          {buttonText && (
            <Button
              intent="primary"
              text={buttonText}
              onClick={buttonOnClick}
              style={{
                height: 20,
                padding: "0 5px",
                minHeight: "unset",
                minWidth: "unset",
                fontSize: 12,
              }}
            />
          )}
        </div>
      </>
    ),
    intent: intent,
  });
};

const filterProperty: ItemPredicate<PlatformProperty> = (
  query,
  property,
  _index,
  exactMatch
) => {
  const fullName = `${property.property_uid}`;
  const normalizedName = fullName.toLowerCase();
  const normalizedQuery = query.trim().toLowerCase();

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  }
  return normalizedName.includes(normalizedQuery);
};

const renderProperty: ItemRenderer<PlatformProperty> = (
  property,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      style={{ paddingLeft: 30, fontFamily: "Barlow" }}
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={property.id}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={`${property.property_uid}`}
    />
  );
};

export default function PlatformPropertyAssignWidget({
  propertyId,
  deviceId,
}: PlatformPropertyAssignWidgetProps) {
  const classes = useStyles();

  const { assignPropertyToDevice } = useDevices();

  const { properties } = useProperties();

  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newPropertyId, setNewPropertyId] = useState<number | null>(null);

  const assignedProperty = useMemo(() => {
    if (propertyId && properties.length) {
      const filteredProperties = properties.filter((c) => c.id === propertyId);
      return filteredProperties.length === 1 ? filteredProperties[0] : null;
    }
    return null;
  }, [propertyId, properties]);

  const [selectedProperty, setSelectedProperty] =
    useState<PlatformProperty | null>(assignedProperty);

  useEffect(() => {
    if (propertyId) {
      setLoading(true);
      if (assignedProperty) {
        setSelectedProperty(assignedProperty);
        setLoading(false);
      }
    } else {
      setSelectedProperty(null);
      setLoading(false);
    }
  }, [assignPropertyToDevice, assignedProperty, deviceId, propertyId]);

  const handlePropertyAssign = useCallback(
    async (property: PlatformProperty) => {
      setLoading(true);
      if (deviceId && property.id) {
        const response = await assignPropertyToDevice(deviceId, property.id);
        if (response.success) {
          setSelectedProperty(property);
        } else {
          showToast(
            "Property assignment failed. Please try again.",
            Intent.DANGER
          );
        }
      }
      setLoading(false);
    },
    [deviceId, assignPropertyToDevice]
  );

  const handleClearSelection = async () => {
    setLoading(true);
    if (deviceId) {
      const response = await assignPropertyToDevice(deviceId, null);
      if (response.success) {
        setSelectedProperty(null);
      } else {
        showToast(
          "Property unassignment failed. Please try again.",
          Intent.DANGER
        );
      }
    }
    setLoading(false);
  };

  const handleViewProperty = async () => {
    if (selectedProperty) {
      window.open(`/properties/${selectedProperty.id}`, "_blank");
    }
  };

  const handleAddProperty = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleEnrollmentSuccess = useCallback((newId: number) => {
    setIsDialogOpen(false);
    setNewPropertyId(newId);
  }, []);

  useEffect(() => {
    if (newPropertyId !== null) {
      const newProperty = properties.find((p) => p.id === newPropertyId);
      if (newProperty) {
        handlePropertyAssign(newProperty);
      }
      setNewPropertyId(null);
    }
  }, [newPropertyId, properties, handlePropertyAssign]);

  return (
    <div>
      <ButtonGroup>
        <Select<PlatformProperty>
          items={properties}
          itemPredicate={filterProperty}
          itemRenderer={renderProperty}
          noResults={
            <MenuItem
              disabled={true}
              text="No results"
              roleStructure="listoption"
              style={{ paddingLeft: 30, fontFamily: "Barlow" }}
            />
          }
          activeItem={selectedProperty}
          onItemSelect={handlePropertyAssign}
          popoverProps={{ matchTargetWidth: true }}
          menuProps={{
            style: { padding: 0, maxHeight: 200, overflow: "auto" },
          }}
        >
          <Button
            icon="home"
            rightIcon="caret-down"
            alignText="left"
            loading={loading}
            className={`${classes.button} ${
              selectedProperty ? "" : classes.selectError
            }`}
            textClassName={
              selectedProperty ? classes.label : classes.labelEmpty
            }
            text={selectedProperty?.property_uid || "Assign a property..."}
          />
        </Select>
        <Button
          icon="cross"
          className={classes.iconButton}
          disabled={!selectedProperty || loading}
          onClick={handleClearSelection}
        />
        <Button
          icon="eye-open"
          className={classes.iconButton}
          disabled={!selectedProperty || loading}
          onClick={handleViewProperty}
        />
        <Button
          icon="plus"
          className={classes.iconButton}
          disabled={selectedProperty !== null || loading}
          onClick={handleAddProperty}
        />
      </ButtonGroup>
      <div className={classes.validationError}>
        {propertyId ? "" : "Please select a property"}
      </div>
      <PlatformPropertyDialog
        isEdit={false}
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        onEnrollmentSuccess={handleEnrollmentSuccess}
        setIsLoadingParent={setLoading}
      />
    </div>
  );
}
