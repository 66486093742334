import { Popover, Spinner } from "@blueprintjs/core";
import mapboxgl, { NavigationControl } from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import {
  PiBatteryChargingVerticalDuotone,
  PiBatteryVerticalEmptyDuotone,
  PiBatteryVerticalFullDuotone,
  PiBatteryVerticalHighDuotone,
  PiBatteryVerticalLowDuotone,
  PiBatteryVerticalMediumDuotone,
  PiBatteryWarningVerticalDuotone,
} from "react-icons/pi";
import { Battery } from "../../../__generated__/types/Battery";
import { MAPBOX_TOKEN } from "../../barcelonaLocations";
import useResizeObserver from "../useResizeObserver";
import PlatformBatteryPopoverContent from "./PlatformBatteryPopoverContent.react";
import { useDevices } from "./devices";

mapboxgl.accessToken = MAPBOX_TOKEN;

const PADDING = 80;

export function getBatteryIconColor(
  batteryLevel: number,
  status: string | undefined,
  stationState: string | undefined
) {
  if (stationState === "FAULT") {
    return "#EC9A3C";
  }

  if (!status) {
    if (batteryLevel >= 60) {
      return "#29A634";
    }

    if (batteryLevel >= 20) {
      return "#EC9A3C";
    }

    return "#e64219";
  }

  if (status === "offline") {
    return "#e64219";
  }

  if (batteryLevel >= 60) {
    return "#29A634";
  }

  if (batteryLevel >= 20) {
    return "#EC9A3C";
  }

  return "#f0f0f0";
}

export function getBatteryIcon(
  batteryLevel: number,
  status: string | undefined,
  stationState: string | undefined
) {
  const color = getBatteryIconColor(batteryLevel, status, stationState);

  if (stationState === "FAULT") {
    return (
      <PiBatteryWarningVerticalDuotone
        style={{ margin: "-2px -8px", padding: 0 }}
        color={color}
      />
    );
  }

  if (status === "offline") {
    return (
      <PiBatteryWarningVerticalDuotone
        style={{ margin: "-2px -8px", padding: 0 }}
        color={color}
      />
    );
  }

  if (status && status.toLowerCase() === "charging") {
    return (
      <PiBatteryChargingVerticalDuotone
        style={{ margin: "-2px -8px", padding: 0 }}
        color={color}
      />
    );
  }

  if (batteryLevel === 100) {
    return (
      <PiBatteryVerticalFullDuotone
        style={{ margin: "-2px -8px", padding: 0 }}
        color={color}
      />
    );
  }

  if (batteryLevel >= 80) {
    return (
      <PiBatteryVerticalHighDuotone
        style={{ margin: "-2px -8px", padding: 0 }}
        color={color}
      />
    );
  }

  if (batteryLevel >= 30) {
    return (
      <PiBatteryVerticalMediumDuotone
        style={{ margin: "-2px -8px", padding: 0 }}
        color={color}
      />
    );
  }

  if (batteryLevel > 0) {
    return (
      <PiBatteryVerticalLowDuotone
        style={{ margin: "-2px -8px", padding: 0 }}
        color={color}
      />
    );
  }
  return (
    <PiBatteryVerticalEmptyDuotone
      style={{ margin: "-2px -8px", padding: 0 }}
      color={color}
    />
  );
}
interface BatteryMarkerProps {
  battery: Battery;
}
const BatteryMarker = ({ battery }: BatteryMarkerProps) => {
  return (
    <Popover
      interactionKind="hover"
      content={<PlatformBatteryPopoverContent battery={battery} />}
    >
      <div
        style={{
          backgroundColor: "black",
          width: "14px",
          height: "18px",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",

          fontSize: "24px",
        }}
      >
        {getBatteryIcon(
          battery?.battery_level ?? 0,
          battery?.battery_status,
          battery?.station_state
        )}
      </div>
    </Popover>
  );
};
function createMarkerElement(component: JSX.Element) {
  // Create a new div element
  const el = document.createElement("div");

  // Mount the React component onto the element
  ReactDOM.render(component, el);

  return el;
}

interface PlatformSiteDeviceMapViewProps {
  height?: string;
}
export default function PlatformSiteDeviceMapView({
  height = "100vh",
}: PlatformSiteDeviceMapViewProps) {
  const [loading, setLoading] = useState(true);

  const { devices, fetchAllDevices } = useDevices();

  useEffect(() => {
    fetchAllDevices();
    const intervalId = setInterval(() => {
      fetchAllDevices();
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearInterval(intervalId);
  }, [fetchAllDevices]);

  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const dimensions = useResizeObserver(mapContainer);

  const markersRef = useRef<mapboxgl.Marker[]>([]);

  useEffect(() => {
    if (map.current || !mapContainer.current) {
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v11",
      center: [0, 0.000001], // Center on the globe (MapBox doesn't like 0,0)
      zoom: 1, // Fully zoomed out initially
      maxZoom: 20,
      minZoom: 1,
      projection: {
        name: "mercator",
      },
      attributionControl: false, // Remove attribution control
    });

    // Add zoom and rotation controls to the map.
    map.current.addControl(new NavigationControl(), "bottom-left");

    // Apply dark theme to navigation controls
    const style = document.createElement("style");
    style.textContent = `
          .mapboxgl-ctrl-group {
            margin-left: 15px !important;
            margin-bottom: 5px !important;
            background: #3c4147 !important;
          }
          .mapboxgl-ctrl-group button {
            background-color: #3c4147 !important;
            border-color: #7c8289 !important;
            border-radius: 0 !important;
          }
          .mapboxgl-ctrl-group button:hover {
            background-color: #545c66 !important;
          }          
          .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon,
          .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon,
          .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
            background-color: transparent;
          }
          .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
          }
          .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
          }
        `;
    document.head.appendChild(style);

    // Remove Mapbox logo
    if (map.current) {
      const logoEl = map.current
        .getContainer()
        .querySelector(".mapboxgl-ctrl-logo");
      if (logoEl) {
        logoEl.remove();
      }
    }
  }, []);

  useEffect(() => {
    if (!devices || !map.current) {
      return;
    }

    // Remove existing markers
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];

    // Prepare to fit all markers within the view
    const bounds = new mapboxgl.LngLatBounds();

    // Create a marker for each device and add to the map
    devices.forEach((device) => {
      if (map.current && device.longitude && device.latitude) {
        const lon = parseFloat(device.longitude);
        const lat = parseFloat(device.latitude);

        if (!isNaN(lon) && !isNaN(lat)) {
          const el = createMarkerElement(<BatteryMarker battery={device} />);

          const marker = new mapboxgl.Marker(el)
            .setLngLat([lon, lat])
            .addTo(map.current);

          // Extend the bounds to include each marker's position
          bounds.extend([lon, lat]);
          markersRef.current.push(marker);
        }
      }
    });

    if (markersRef.current.length > 0) {
      map.current.fitBounds(bounds, {
        padding: {
          top: PADDING,
          bottom: PADDING,
          left: PADDING,
          right: PADDING,
        },
        maxZoom: 7,
        animate: false,
      });
    }

    setLoading(false);
  }, [devices]);

  useEffect(() => {
    map.current?.resize();
  }, [dimensions]);

  return (
    <div
      ref={mapContainer}
      className="dark"
      style={{
        height,
        width: "100%",
        minWidth: "200px", // Needed to prevent bound errors
        minHeight: "200px", // Needed to prevent bound errors
        position: "relative",
      }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            backdropFilter: "blur(2px)",
            zIndex: 1, // On top of the map
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}
